// vim: et sw=2 ts=2

;(function ($, G) {
  G.medshr = G.medshr || {}
  G.medshr.analytics = G.medshr.analytics || {}

  var $promo

  $.cf.installer('.video-js', function ($video) {
    if (!$promo) $promo = $video.parent().clone()
    var repeat = 0
    function install() {
      if (typeof videojs === 'undefined') {
        if (++repeat < 20) {
          setTimeout(install, 500)
        }
        return
      }
      videojs($video.get(0)).ready(function () {
        var myPlayer = this
        var played = false

        var label = $video.attr('poster')
        if (label) label = label.replace(/\.[a-z]+$/, '')

        myPlayer.on('play', function () {
          if (!played) {
            played = true
            G.medshr.analytics.trackEvent('Promo Movie', 'Play', label)
          }
        })

        myPlayer.on('ended', function () {
          played = false
        })
      })
    }
    install()
  })

  $.cf.installer('.video-lightbox', function ($a) {
    $a.on('click', function (e) {
      $(document.documentElement).addClass('animation-none')

      // create the video lightbox
      var $block = $('<div>', { class: '' }),
        $video = $('<video>', {
          class: 'video--promo video-js vjs-default-skin vjs-big-play-centered',
          controls: true,
        }),
        $source = $('<source>', {
          src: 'https://static.medshr.net/video/medshr-for-doctors.mp4',
        }),
        $container = $('<div>', { class: 'video-lightbox-container' })
      $video.append($source)
      $block.append($video)
      $container.append($block)
      $(document.body).append($container)

      $container.css('opacity', 0)

      // set the video size inside the lightbox to fit into the available space
      // TODO: don't hardcode this aspect ratio
      var ratio = 1371 / 720,
        height = Math.round(0.8 * $container.outerHeight()),
        width = height * ratio
      $block.css({
        height: height + 'px',
        width: width + 'px',
        margin:
          Math.round(($container.outerHeight() - height) / 2) + 'px auto 0',
      })
      $video.attr('width', width).attr('height', height)

      var closeLightbox = function () {
        $(document.documentElement).removeClass('animation-none')
        $container.animate({ opacity: 0 }, 260, function () {
          $container.remove()
        })
      }

      // close lightbox if user clicks on background
      $container.on('click', function (e) {
        if ($(e.target).closest('.video-js').length) {
          return
        }
        closeLightbox()
      })

      // play when displayed
      $container.animate({ opacity: 1 }, 260, function () {
        videojs($video.get(0)).ready(function () {
          // this.requestFullscreen();
          this.play()
          // hide when video ends
          this.on('ended', function () {
            closeLightbox()
            if ($('.landing__content--a').is(':visible')) {
              $('#c-email').focus()
            }
            $(document.body).animate(
              {
                scrollTop:
                  $('.landing__title').position().top -
                  $('.guest-page-header').height(),
              },
              360
            )
          })
        })
      })

      return false
    })
  })
})(jQuery, window)
