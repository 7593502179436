// vim: et sw=2 ts=2
;(function ($) {
  var DevicePreview = function ($root, screens) {
    this.currentScreenIndex = 0

    this.$root = $root
    this.$viewport = $('<div>', { class: 'device-preview__viewport' })
    this.$screens = $('<div>', {
      class:
        'device-preview__screens device-preview__screens--' + screens.length,
    })

    var self = this
    $.each(screens, function (i, screen) {
      var $screen = self.createScreen(screen)
      screen.$el = $screen
      self.$screens.append($screen)
    })

    $root.children().remove()
    $root.append(this.$viewport.append(this.$screens))

    this.$statusbar = $(
      "<div class='device-preview__statusbar'>" +
        "<span class='device-preview__signal'></span>" +
        "<span class='device-preview__time'><span class='js-current-time'></span></span>" +
        "<span class='device-preview__battery'></span>" +
        '</div>'
    )
    this.$viewport.append(this.$statusbar)

    this.updateTime()
    this.progressThrough(screens)

    this._finish = false
  }

  DevicePreview.prototype.finish = function () {
    this._finish = true
  }

  DevicePreview.prototype.progressThrough = function (screens) {
    var self = this
    var screen = screens[this.currentScreenIndex++]

    if (this.currentScreen) {
      this.currentScreen.$el
        .addClass('device-preview__screen--leaving')
        .removeClass('device-preview__screen--active')
    }
    screen.$el.addClass('device-preview__screen--entering')

    this.$statusbar.toggleClass(
      'device-preview__statusbar--visible',
      !(
        screen.hideStatusbar ||
        screen.screen_id === 'splash' ||
        screen.device_image_url
      )
    )

    this.$root.trigger('device_preview_transition_start', [
      self.currentScreenIndex - 1,
      transition_duration,
    ])

    var transition_duration = 260

    setTimeout(function () {
      if (self.currentScreen) {
        self.currentScreen.$el.removeClass('device-preview__screen--leaving')
      }
      screen.$el
        .removeClass('device-preview__screen--entering')
        .addClass('device-preview__screen--active')

      self.currentScreen = screen

      self.$root.trigger('device_preview_transition_end', [
        self.currentScreenIndex - 1,
      ])

      if (!self._finish && self.currentScreenIndex < screens.length) {
        setTimeout(
          function () {
            self.progressThrough(screens)
          },
          self._finish ? 1 : 1000 * screen.duration
        )
      } else {
        if (!self._finish) {
          self.$root.trigger('device_preview_transitions_complete', [
            self.currentScreen.duration,
          ])
        }
        self._finish = false
      }
    }, transition_duration)
  }

  DevicePreview.prototype.createScreen = function (screen) {
    if (typeof screen.duration === 'undefined') {
      screen.duration = 5
    }
    if (screen.screen_id === 'splash') {
      return $('<div>', {
        class:
          'device-preview__screen device-preview__screen--splash device-preview__screen--active',
      })
    }
    if (screen.screen_id === 'resource') {
      if (screen.device_image_url) {
        return $('<div>', {
          class: 'device-preview__screen device-preview__screen--resource',
        }).append(
          $('<img>', {
            src: screen.device_image_url.replace('{image_size}', 'original'),
          })
        )
      }
      var creator = screen.creator
      var $creator = creator
        ? $(
            "<div class='device-preview__resource-creator media media--small'></div>"
          )
            .append(
              $('<img>', {
                src: creator.image_url,
                width: 36,
                height: 36,
                class: 'media__img device-preview__resource-creator-image',
              })
            )
            .append(
              $('<div>', { class: 'media__body' })
                .append(
                  $('<div>', {
                    text: creator.name,
                    class: 'device-preview__resource-creator-name',
                  })
                )
                .append(
                  $('<div>', {
                    text: creator.job_title,
                    class: 'device-preview__resource-creator-job_title',
                  })
                )
            )
        : null
      return $('<div>', {
        class: 'device-preview__screen device-preview__screen--resource',
      })
        .append(
          $('<div>', { class: 'device-preview__resource-header' })
            .append(
              "<div class='device-preview__navbar'><div class='device-preview__navbutton navbutton--back'></div><div class='device-preview__navbutton navbutton--share'></div><div class='device-preview__navbutton navbutton--more'></div></div>"
            )
            .append($creator)
            .append(
              $('<div>', {
                class: 'device-preview__resource-title',
                text: screen.title,
              })
            )
            //.append($("<div>", {'class': 'device-preview__resource-meta', 'text': screen.meta}))
            .append(
              $('<div>', {
                class: 'device-preview__resource-categories',
                text: screen.categories,
              })
            )
        )
        .append(
          $('<div>', { class: 'device-preview__resource-body' }).append(
            $('<div>', {
              class:
                'device-preview__resource-description' +
                (screen.description
                  ? ' device-preview__resource-description--unblurred'
                  : ''),
              text:
                screen.description ||
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet maximus nisi, ut placerat nisi. Aenean a tortor at odio fringilla posuere a non lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus fermentum quam sed sapien vulputate, ut rhoncus nisi tincidunt. Mauris volutpat lobortis tellus, vel faucibus justo commodo vitae.',
            })
          )
        )
    } else if (screen.screen_id === 'learning_module') {
      var description = screen.description
      while (description.length > 200) {
        var prev = description
        description = description.replace(/[^\.]+\.?$/, '')
        if (description === prev) break
      }
      if (description.length === 0) {
        description = screen.description
      } else if (description !== screen.description) {
        description += '..'
      }
      var creator = screen.creator
      var $creator
      if (creator.image_url && creator.job_title) {
        $creator = $(
          "<div class='device-preview__resource-creator media media--small'></div>"
        )
          .append(
            $('<img>', {
              src: creator.image_url,
              width: 26,
              height: 26,
              class: 'media__img device-preview__resource-creator-image',
            })
          )
          .append(
            $('<div>', { class: 'media__body' }).append(
              $('<div>', {
                text: creator.name,
                class: 'device-preview__resource-creator-name',
              })
            )
          )
      } else {
        $creator = $(
          "<div class='device-preview__resource-creator'></div>"
        ).append(
          $('<div>', {
            text: creator.name,
            class: 'device-preview__resource-creator-name',
          })
        )
      }

      return $('<div>', {
        class: 'device-preview__screen device-preview__screen--' + screen.type,
      }).append(
        $('<div>', {
          class: 'device-preview__group-header',
          style: screen.bg_image_url
            ? 'background: rgba(3, 20, 54, .9) url(' +
              screen.bg_image_url.replace('{image_size}', 'large') +
              ') no-repeat 50% 50%; background-size: 300%'
            : '',
        })
          .append(
            $('<div>', {
              style:
                'background: rgba(0, 0, 0, .7); position: absolute; top:0; right: 0; bottom: 0; left: 0',
            })
          )
          .append(
            "<div class='device-preview__navbar'><div class='device-preview__navbutton navbutton--back'></div></div>"
          )
          .append($('<div>', { class: 'device-preview__learning-logo' }))
          .append(
            $('<div>', {
              class: 'device-preview__learning-title',
              text: screen.title,
            })
          )
          .append($creator)
          .append(
            $('<div>', {
              class: 'device-preview__learning-description',
              text: description,
            })
          )
      )
    } else if (screen.screen_id === 'group' || screen.screen_id === 'user') {
      var description = screen.description
      while (description.length > 200) {
        var prev = description
        description = description.replace(/[^\.]+\.?$/, '')
        if (description === prev) break
      }
      if (description.length === 0) {
        description = screen.description
      } else if (description !== screen.description) {
        description += '..'
      }

      return $('<div>', {
        class: 'device-preview__screen device-preview__screen--' + screen.type,
      }).append(
        $('<div>', {
          class: 'device-preview__group-header',
          style:
            'background: rgba(3, 20, 54, .9) url(' +
            screen.bg_image_url.replace('{image_size}', 'profile-background') +
            ') no-repeat 50% 50%; background-size: 300%',
        })
          .append(
            "<div class='device-preview__navbar'><div class='device-preview__navbutton navbutton--back'></div></div>"
          )
          .append(
            $('<img>', {
              src: screen.image_url.replace('{image_size}', 'profile-large'),
              width: 75,
              height: 75,
              class: 'device-preview__group-profile-image',
            })
          )
          .append(
            $('<div>', {
              class: 'device-preview__group-title',
              text: screen.title,
            })
          )
          .append(
            $('<div>', {
              class: 'device-preview__group-description',
              text: description,
            })
          )
      )
    }
  }

  DevicePreview.prototype.updateTime = function () {
    var self = this
    var time = new Date()
    var hour = time.getHours()
    var minutes = '' + time.getMinutes()
    if (minutes.length === 1) minutes = '0' + minutes
    this.$statusbar
      .find('.js-current-time')
      .text(
        (hour > 12 ? hour % 12 : hour) +
          ':' +
          minutes +
          (hour >= 12 ? ' PM' : ' AM')
      )
    setTimeout(function () {
      self.updateTime()
    }, (60 - time.getSeconds()) * 1000)
  }

  $(window).on('load', function () {
    function setup() {
      $.cf.installer('.device-preview', function ($root) {
        new DevicePreview($root, $root.data('device-screens'))
      })
    }

    var ratio = 1
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (
      window.screen.systemXDPI !== undefined &&
      window.screen.logicalXDPI !== undefined &&
      window.screen.systemXDPI > window.screen.logicalXDPI
    ) {
      // Only allow for values > 1
      ratio = window.screen.systemXDPI / window.screen.logicalXDPI
    } else if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio
    }
    ratio = ratio == 1 ? '' : '@2x'

    if (window.device_preview_preloads) {
      var c = window.device_preview_preloads.length
      for (var i = 0; i < window.device_preview_preloads.length; ++i) {
        var img = new Image()
        img.src = window.device_preview_preloads[i].replace(':ratio', ratio)
        img.onload = function () {
          if (--c <= 0) {
            setup()
          }
        }
      }
    }

    $('#content').waitForImages(function () {
      $(document.documentElement).addClass('load-complete')
      if (!window.device_preview_preloads) {
        setup()
      }
    })
  })
})(window.jQuery)
;(function (factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory)
  } else if (typeof exports === 'object') {
    // CommonJS / nodejs module
    module.exports = factory(require('jquery'))
  } else {
    // Browser globals
    factory(jQuery)
  }
})(function ($) {
  // Namespace all events.
  var eventNamespace = 'waitForImages'

  // CSS properties which contain references to images.
  $.waitForImages = {
    hasImageProperties: [
      'backgroundImage',
      'listStyleImage',
      'borderImage',
      'borderCornerImage',
      'cursor',
    ],
    hasImageAttributes: ['srcset'],
  }

  // Custom selector to find all `img` elements with a valid `src` attribute.
  $.expr.pseudos['has-src'] = function (obj) {
    // Ensure we are dealing with an `img` element with a valid
    // `src` attribute.
    return $(obj).is('img[src][src!=""]')
  }

  // Custom selector to find images which are not already cached by the
  // browser.
  $.expr.pseudos.uncached = function (obj) {
    // Ensure we are dealing with an `img` element with a valid
    // `src` attribute.
    if (!$(obj).is(':has-src')) {
      return false
    }

    return !obj.complete
  }

  $.fn.waitForImages = function () {
    var allImgsLength = 0
    var allImgsLoaded = 0
    var deferred = $.Deferred()

    var finishedCallback
    var eachCallback
    var waitForAll

    // Handle options object (if passed).
    if ($.isPlainObject(arguments[0])) {
      waitForAll = arguments[0].waitForAll
      eachCallback = arguments[0].each
      finishedCallback = arguments[0].finished
    } else {
      // Handle if using deferred object and only one param was passed in.
      if (arguments.length === 1 && typeof arguments[0] === 'boolean') {
        waitForAll = arguments[0]
      } else {
        finishedCallback = arguments[0]
        eachCallback = arguments[1]
        waitForAll = arguments[2]
      }
    }

    // Handle missing callbacks.
    finishedCallback = finishedCallback || $.noop
    eachCallback = eachCallback || $.noop

    // Convert waitForAll to Boolean
    waitForAll = !!waitForAll

    // Ensure callbacks are functions.
    if (
      typeof finishedCallback !== 'function' ||
      typeof eachCallback !== 'function'
    ) {
      throw new TypeError('An invalid callback was supplied.')
    }

    this.each(function () {
      // Build a list of all imgs, dependent on what images will
      // be considered.
      var obj = $(this)
      var allImgs = []
      // CSS properties which may contain an image.
      var hasImgProperties = $.waitForImages.hasImageProperties || []
      // Element attributes which may contain an image.
      var hasImageAttributes = $.waitForImages.hasImageAttributes || []
      // To match `url()` references.
      // Spec: http://www.w3.org/TR/CSS2/syndata.html#value-def-uri
      var matchUrl = /url\(\s*(['"]?)(.*?)\1\s*\)/g

      if (waitForAll) {
        // Get all elements (including the original), as any one of
        // them could have a background image.
        obj
          .find('*')
          .addBack()
          .each(function () {
            var element = $(this)

            // If an `img` element, add it. But keep iterating in
            // case it has a background image too.
            if (element.is('img:has-src')) {
              allImgs.push({
                src: element.attr('src'),
                element: element[0],
              })
            }

            $.each(hasImgProperties, function (i, property) {
              var propertyValue = element.css(property)
              var match

              // If it doesn't contain this property, skip.
              if (!propertyValue) {
                return true
              }

              // Get all url() of this element.
              while ((match = matchUrl.exec(propertyValue))) {
                allImgs.push({
                  src: match[2],
                  element: element[0],
                })
              }
            })

            $.each(hasImageAttributes, function (i, attribute) {
              var attributeValue = element.attr(attribute)
              var attributeValues

              // If it doesn't contain this property, skip.
              if (!attributeValue) {
                return true
              }

              // Check for multiple comma separated images
              attributeValues = attributeValue.split(',')

              $.each(attributeValues, function (i, value) {
                // Trim value and get string before first
                // whitespace (for use with srcset).
                value = $.trim(value).split(' ')[0]
                allImgs.push({
                  src: value,
                  element: element[0],
                })
              })
            })
          })
      } else {
        // For images only, the task is simpler.
        obj.find('img:has-src').each(function () {
          allImgs.push({
            src: this.src,
            element: this,
          })
        })
      }

      allImgsLength = allImgs.length
      allImgsLoaded = 0

      // If no images found, don't bother.
      if (allImgsLength === 0) {
        finishedCallback.call(obj[0])
        deferred.resolveWith(obj[0])
      }

      $.each(allImgs, function (i, img) {
        var image = new Image()
        var events = 'load.' + eventNamespace + ' error.' + eventNamespace

        // Handle the image loading and error with the same callback.
        $(image).one(events, function me(event) {
          // If an error occurred with loading the image, set the
          // third argument accordingly.
          var eachArguments = [
            allImgsLoaded,
            allImgsLength,
            event.type == 'load',
          ]
          allImgsLoaded++

          eachCallback.apply(img.element, eachArguments)
          deferred.notifyWith(img.element, eachArguments)

          // Unbind the event listeners. I use this in addition to
          // `one` as one of those events won't be called (either
          // 'load' or 'error' will be called).
          $(this).off(events, me)

          if (allImgsLoaded == allImgsLength) {
            finishedCallback.call(obj[0])
            deferred.resolveWith(obj[0])
            return false
          }
        })

        image.src = img.src
      })
    })

    return deferred.promise()
  }
})
