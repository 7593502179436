// vim: et sw=2 ts=2

;(function ($, G) {
  G.medshr = G.medshr || {}
  G.medshr.analytics = G.medshr.analytics || {}

  $.extend($.easing, {
    easeInQuad: function (x, t, b, c, d) {
      return c * (t /= d) * t + b
    },
  })

  var disabledFade = false,
    disabledFadeStack = []

  var pushDisabledFade = function (v) {
    disabledFadeStack.push(disabledFade)
    disabledFade = v
  }
  var popDisabledFade = function () {
    disabledFade = disabledFadeStack.pop()
  }

  $.fn.fadeIn = function (speed, callback) {
    if (disabledFade) speed = 0
    this.show()
    return this.animate({ opacity: 1 }, speed, 'easeInQuad', function () {
      if (typeof callback === 'function') {
        callback.call(this)
      }
    })
  }

  $.fn.fadeOut = function (speed, callback) {
    if (disabledFade) speed = 0
    return this.animate({ opacity: 0 }, speed, 'easeInQuad', function () {
      $(this).hide()
      if (typeof callback === 'function') {
        callback.call(this)
      }
    })
  }

  function disableFieldset($field) {
    $field.addClass('fm-field--disabled')
    $field.find('select, input, button').prop('disabled', true)
    $field.find('.error').remove()
    $field.removeClass('fm-error')
  }

  function enableFieldset($field) {
    $field.removeClass('fm-field--disabled')
    $field.find('select, input, button').prop('disabled', false)
  }

  // Begin: Device Preview titles and transition to to Signup Form

  $.cf.installer('.device-preview', function ($root) {
    var $page = $('.landing__banner')
    var $button = $page.find('form button[type=submit]')

    $button.on('click', function () {
      if ($(document.body).hasClass('ab-signup-b')) {
        $page.find('.landing__signup-link').click()
      }
    })
  })

  // End: Device Preview titles and transition to to Signup Form

  $.ajax({
    url: '/api/auth/check',
    success: function (response) {
      if (response.responseJSON && response.responseJSON.success) {
        if (/(auth|join)/.test(document.location.pathname)) {
          document.location.href = '/auth/check' + document.location.search
        } else if (!/ts=/.test(document.location.search)) {
          document.location.href =
            document.location.pathname +
            (document.location.search ? document.location.search + '&' : '?') +
            'ts=' +
            new Date().getTime()
        }
      }
    },
  })

  // hide fixed header on android mobiles when keyboard is up, as there's
  // not enough space to see the text field. Mobile Safari does this automatically.
  $(document).ready(function () {
    $('input, select').on({
      focus: function () {
        if (
          G.cfui.mediaQuery('palm') &&
          !/Mobile Safari/.test(navigator.userAgent)
        ) {
          $(document.documentElement).addClass('js-keyboard-visible')
        }
      },
      blur: function () {
        $(document.documentElement).removeClass('js-keyboard-visible')
      },
    })
  })

  // Transition between Home / Join / Signin screens without page refresh
  //
  $.cf.installer('.page-landing[data-ajax-views]', function ($root) {
    var views = $root.data('ajax-views')
    if (!views) return
    var $header = $('.guest-page-header')
    var $body = $('.page-landing')
    var $home = $(views['/'])
    var $login = $(views['/auth/login'])
    var $join = $(views['/join'])
    var nodes = {
      home: {
        header: $home.find('.guest-page-header'),
        body: $home.find('.page-landing'),
      },
      login: {
        header: $login.find('.guest-page-header'),
        body: $login.find('.page-landing'),
      },
      join: {
        header: $join.find('.guest-page-header'),
        body: $join.find('.page-landing'),
      },
    }
    var transition = function (node) {
      $(document.body).addClass('landing-transition--enter')
      setTimeout(function () {
        $header.html(node.header.html())
        $body.html(node.body.html()).cf_install()
        $body.find('p.error').remove()
        setTimeout(function () {
          $(document.body).removeClass('landing-transition--enter')
        }, 10)
      }, 260)
    }
    $(document.body).on('click', '.js-btn--home', function (e) {
      transition(nodes.home)
      return false
    })
    $(document.body).on('click', '.js-btn--login', function (e) {
      transition(nodes.login)
      return false
    })
    $(document.body).on(
      'click',
      '.js-btn--join, .js-btn--join-now',
      function (e) {
        transition(nodes.join)
        return false
      }
    )
  })

  $.cf.installer('.auth-login--welcome-back form', function ($form) {
    var $checkbox = $form.find('.fm-property--terms__check[name=terms]')
    var $button = $form.find('.btn-primary')
    if ($checkbox.length && $button.length) {
      $checkbox.on('change', function () {
        if (this.checked) {
          $button.prop('disabled', false)
        } else {
          $button.prop('disabled', true)
        }
      })
      $checkbox.triggerHandler('change')
    }
  })

  $.cf.installer('form.auth-register, form.auth-form', function ($form) {
    if (typeof branch !== 'undefined') {
      branch.data(function (err, data) {
        if (data) {
          $form.append(
            $('<input>', {
              type: 'hidden',
              name: 'branch',
              value: JSON.stringify(data),
            })
          )
        }
      })
    }

    $form.on('submit.grecaptcha', function (e) {
      e.preventDefault()
      grecaptcha.enterprise.ready(function () {
        var key = $('body').attr('data-grecaptcha-key')
        return grecaptcha.enterprise
          .execute(key, { action: 'signup' })
          .then(function (token) {
            var $input = $form.find('[name="grecaptcha"]')
            if ($input.length) $input = $input.get(0)
            else {
              $form.off('.grecaptcha')
              $input = $('<input>', {
                type: 'hidden',
                name: 'grecaptcha',
                value: token,
              })
              $form.append($input)
            }
            $form.get(0).submit()
          })
      })
      return false
    })
  })

  // Convert in-form error messages to header error bar
  //
  $(document).ready(function () {
    var $fm_errors = $('form p.error')
    if ($fm_errors.length) {
      var $close_errors = $(
          "<i class='fa fa-close fa-2x editor-errors__close'></i>"
        ),
        $error_text = $('<div>', { class: 'editor-errors__text' }),
        $errors = $('<div>', { class: 'editor-errors' })
          .append($error_text)
          .append($close_errors)
          .appendTo($('.guest-page-header')),
        missing = [],
        errors = []
      $fm_errors.each(function () {
        var $this = $(this),
          m = $this.text().match(/(.+) is required$/)
        if ($this.closest('.fm-field').css('visibility') === 'hidden') {
          return
        }
        var message
        if (m) {
          message = m[1]
          if (missing.length) message = message.toLowerCase()
          missing.push(message)
        } else {
          message = $this.text()
          errors.push(message)
        }
        $this.remove()
      })
      if (missing.length) {
        var w = missing.length === 1 ? ' is ' : ' are '
        var last = missing.pop()
        var text =
          missing.length === 0 ? last : missing.join(', ') + ' and ' + last
        $error_text.text(text + w + ' required')
      } else $error_text.text(errors.join(', '))
      $errors.on('click', function () {
        $errors.fadeOut(260, function () {
          $errors.hide()
        })
      })
    }
  })

  // Profile form input validation
  //
  $.cf.installer('.auth-profile-editor', function ($form) {
    var $first_name = $form.find('.fm-property-first_name input'),
      $last_name = $form.find('.fm-property-last_name input'),
      $kind = $form.find('.fm-property-kind select, input[name=c-kind]'),
      $seniority = $form.find(
        '.fm-property-seniority select, input[name=c-kind_seniority]'
      ),
      $main_institution = $form.find(
        '.fm-property-main_institution input[type=hidden]'
      ),
      $primary_specialty = $form.find('.fm-property-primary_spec select'),
      $primary_subspecialty = $form.find('.fm-property-primary_subspec select'),
      $telephone_number = $form.find('.fm-property-contact_telephone input'),
      $job_title = $form.find('.fm-property-job_title input'),
      $contact_message = $form.find('.fm-property-other_message textarea'),
      $error_text = $('<div>', { class: 'editor-errors__text' }),
      $close_errors = $(
        "<i class='fa fa-close fa-2x editor-errors__close'></i>"
      ),
      $errors = $('<div>', { class: 'editor-errors' })
        .append($error_text)
        .append($close_errors)
        .appendTo($('.guest-page-header')),
      show_errors = $form.find('.fm-error').length > 0,
      errors = {},
      error_names = {
        first_name: 'name',
        last_name: 'name',
        kind: 'role',
        kind_seniority: function () {
          if ($kind.val() === 'other') return 'field'
          if ($kind.val() === 'student') return 'stage'
          return 'grade'
        },
        main_institution: 'primary institution',
        primary_spec: 'primary specialty',
        primary_spec_sub: 'sub-specialty',
        contact_telephone: 'telephone number',
        other_message: 'message',
        job_title: 'job title',
      },
      error_order = [
        'name',
        'role',
        'grade',
        'field',
        'stage',
        'primary institution',
        'primary specialty',
        'sub-specialty',
        'job title',
        'telephone number',
        'message',
      ],
      autoshow_errors = true
    $errors.hide()

    $errors.on('click', function () {
      autoshow_errors = false
      $errors.fadeOut(260, function () {
        $errors.hide()
      })
    })

    var update_errors = function () {
      var names = []
      for (var i = 0; i < error_order.length; ++i) {
        if (errors[error_order[i]]) {
          names.push(error_order[i])
        }
      }
      if (names.length) {
        var message = 'Your '
        if (names.length === 1) {
          message += names[0] + ' is '
        } else {
          var last = names.pop()
          message += names.join(', ') + ' and ' + last + ' are '
        }
        message += 'required'
        $error_text.text(message)
        if (autoshow_errors) {
          $errors.show().fadeIn(10)
        }
      } else {
        $error_text.text('')
        $errors.hide()
      }
    }

    var error = function ($control) {
      var $field = $control.closest('.fm-field')
      $field.addClass('fm-error')
      var err = error_names[$control.attr('name').substr(2)]
      if (typeof err === 'function') err = err()
      errors[err] = true
    }

    var validate = function () {
      var has_errors = false
      errors = {}
      $form.find('.fm-error').removeClass('fm-error').find('.error').remove()
      if ($first_name.val().length === 0) {
        error($first_name)
        has_errors = true
      } else if ($last_name.val().length === 0) {
        error($last_name)
        has_errors = true
      }
      if ($kind.val().length === 0) {
        error($kind)
        has_errors = true
      } else if ($kind.val() === 'professional' || $kind.val() === 'nurse') {
        if ($job_title.val().length === 0) {
          error($job_title)
          has_errors = true
        }
      } else if ($kind.val() === 'other') {
        if ($job_title.val().length === 0) {
          error($job_title)
          has_errors = true
        }
        if ($seniority.val() !== 'advisory_board_mod') {
          if ($telephone_number.val().length === 0) {
            error($telephone_number)
            has_errors = true
          }
          if ($contact_message.val().length === 0) {
            error($contact_message)
            has_errors = true
          }
        }
      }

      if (
        $seniority.children() &&
        $seniority.children().length > 1 &&
        $seniority.val().length === 0
      ) {
        error($seniority)
        has_errors = true
      }

      if ($kind.val() !== 'other') {
        if (
          $main_institution.val().length === 0 ||
          $main_institution.val() === '0'
        ) {
          error($main_institution)
          has_errors = true
        }
        if (
          (!$primary_specialty.val() ||
            $primary_specialty.val().length === 0) &&
          $kind.val() !== 'student'
        ) {
          error($primary_specialty)
          has_errors = true
        }
        // if ($primary_subspecialty.children().length > 1 && $primary_subspecialty.val().length === 0) {
        //   error($primary_subspecialty);
        //   has_errors = true;
        // }
      }
      update_errors()
      return !has_errors
    }

    $form
      .find('input, select, textarea')
      .on('change click keyup', function (e) {
        if (e.target && show_errors) {
          setTimeout(function () {
            validate()
          }, 1)
        }
      })

    $form.on('submit', function (e) {
      autoshow_errors = true
      show_errors = true
      if (!validate()) {
        $form.find('.fm-error').first().find('input, select').get(0).focus()
        return false
      }
      grecaptcha.enterprise.ready(function () {
        var key = $('body').attr('data-grecaptcha-key')
        return grecaptcha.enterprise
          .execute(key, { action: 'signup' })
          .then(function (token) {
            var $input = $form.find('[name="grecaptcha"]')
            if ($input.length) $input = $input.get(0)
            else {
              $input = $('<input>', {
                type: 'hidden',
                name: 'grecaptcha',
                value: token,
              })
              $form.append($input)
              $form.get(0).submit()
            }
          })
      })
      return false
    })
  })

  // Changing kind to / from "student" toggles institution labels between "study" and "work", and toggles visibility of specialty section
  $.cf.installer('.fm-property-kind select', function ($select) {
    var $label = $('.fm-property-main_institution label'),
      $legend = $('.fm-fieldset--institution legend'),
      $grade_label = $('.fm-property-seniority label'),
      $grade_select = $('.fm-property-seniority select'),
      $registration_id_label = $('.fm-property-registration_id label'),
      $registration_id_field = $('.fm-property-registration_id')
    $select.on('change', function (e) {
      if ($(this).val() === 'student') {
        $label.text($label.text().replace(/work( \/ study)?/, 'study'))
        $legend.text($legend.text().replace(/work( \/ study)?/, 'study'))
        $grade_label.text('What stage are you at?')
      } else if ($(this).val() === 'other') {
        $grade_label.text('Your field')
      } else if ($(this).val()) {
        $label.text($label.text().replace(/(work \/ )?study/, 'work'))
        $legend.text($legend.text().replace(/(work \/ )?study/, 'work'))
      }

      if ($(this).val() === 'other' || !$(this).val()) {
        $registration_id_field.hide()
      } else if ($(this).val() === 'doctor') {
        $registration_id_label.text($registration_id_field.data('label-doctor'))
        $registration_id_field.show()
      } else {
        $registration_id_label.text($registration_id_field.data('label'))
        $registration_id_field.show()
      }
    })
  })

  // Change options in a select based on value selected in another.
  // sub-specialties is a child select of specialties, seniority a child select of kind
  $.cf.installer('.js-child-select-update', function ($field) {
    var initialising = true
    function show($control, callback) {
      var $field = $control.closest('.fm-field')
      if (initialising) {
        $field.css('opacity', 1)
        if (callback) callback($field)
      } else {
        $field.fadeIn(0, callback)
      }
    }
    function hide($control, callback) {
      var $field = $control.closest('.fm-field')
      if (initialising) {
        $field.css('opacity', 0)
        if (callback) callback($field)
      } else {
        $field.fadeOut(0, callback)
      }
    }

    var $select = $field.find('select')
    var $child_select = $($field.data('child-select'))
    var options = $field.data('child-options')

    if ($child_select.is('select')) {
      $select
        .on('change', function (e) {
          var current_value = $child_select.val(),
            exists = false,
            opts = options[$select.val()] || []
          $child_select.find("[value!='']").remove()
          $.each(opts, function (value, label) {
            if (current_value == value) exists = true
            $child_select.append($('<option>', { value: value, text: label }))
          })
          if (exists) $child_select.val(current_value)
          else {
            if (opts.length === 0) {
              hide($child_select, function () {
                $child_select.val('')
              })
            } else {
              $child_select.val('')
              $child_select
                .children()
                .first()
                .text($field.data('child-placeholder'))
              show($child_select)
            }
          }
        })
        .triggerHandler('change')
    } else {
      var _id = 0

      $select
        .on('change', function (e) {
          var opts = options[$select.val()] || [],
            $ul = $('<ul>', { class: 'list-bare' })
          $child_select
            .closest('.fm-fieldset')
            .find('.cms-editor-enumfield__tools')
            .remove()
          $child_select.find('ul').remove()

          $.each(opts, function (value, label) {
            var id = 'c-primary_spec_sub-check-' + _id++,
              $check = $('<input>', {
                type: 'checkbox',
                class: 'checkbox__input fs-unmask',
                id: id,
                name: 'c-primary_spec_sub[]',
                value: value,
              }),
              $label = $('<label>', {
                for: id,
                text: label,
                class: 'fs-unmask',
              }),
              $item = $('<li>', {
                class: 'cms-editor-enumfield__checkbox fs-unmask',
              }).append(
                $('<span>', { class: 'checkbox fs-unmask' }).append(
                  $check,
                  $label
                )
              )
            $ul.append($item)
          })

          if (opts.length === 0) {
            hide($child_select, function () {})
          } else {
            $child_select.append($ul)
            show($child_select)
          }
        })
        .triggerHandler('change')
    }

    initialising = false
  })

  // Auto-update primary specialty based on role selection
  // e.g. Dentists -> Dentistry, Students -> All
  // Also adjust seniority field label for students.
  $.cf.installer(
    '.fm-property-kind select, input[name=c-kind]',
    function ($kind_select) {
      var $seniority_label = $('.fm-property-seniority label')
      var original_seniority_label = $seniority_label.text()
      var $seniority_select = $(
        '.fm-property-seniority select, input[name=c-kind_seniority]'
      )
      var $job_title_field = $('.fm-property-job_title')
      var $job_title_input = $job_title_field.find('input')
      var has_job_title_autocomplete = false
      var original_job_title_label = $job_title_field.find('label').text()
      var $form = $kind_select.parents('form')
      var $button = $form.find('.fm-footer .btn-primary')
      var submit_text = $button.text()
      var initialising = true
      function updateRoleSpecialties() {
        var kind = $kind_select.val(),
          seniority = $seniority_select.val()
        if (kind === 'other' && seniority !== 'advisory_board_mod') {
          $button.text('Request access')
          if (has_job_title_autocomplete) {
            $job_title_input.autocomplete('destroy').val('')
            has_job_title_autocomplete = false
          }
          $job_title_field
            .addClass('fm-property-job_title--other')
            .fadeIn(initialising ? 0 : 260)
          $('.fm-fieldset--institution, .fm-fieldset--specialty').fadeOut(
            initialising ? 0 : 260,
            function () {
              $(this).css('display', 'none')
              $(
                '.fm-fieldset--other-contact, .fm-fieldset--other-message, .fm-description--other-contact'
              )
                .css('display', 'block')
                .fadeIn(initialising ? 0 : 260)
            }
          )
        } else {
          if (kind === 'professional' || kind === 'nurse') {
            var cache = {}
            if (has_job_title_autocomplete) {
              $job_title_input.autocomplete('destroy')
              has_job_title_autocomplete = false
            }
            $job_title_input.autocomplete({
              classes: {
                'ui-autocomplete': 'fs-unmask',
              },
              minLength: 0,
              source: function (request, response) {
                if (cache[request.term]) {
                  response(cache[request.term])
                  return
                }
                $.getJSON(
                  '/api/job-titles/search?autocomplete=1&kind=' +
                    kind +
                    '&term=' +
                    escape(request.term)
                ).success(function (res) {
                  var found = false
                  var lterm = request.term.toLowerCase()
                  var items = res.results.map(function (item) {
                    if (item.label.toLowerCase() === lterm) {
                      found = true
                    }
                    return item
                  })
                  if (!found && request.term.length > 0) {
                    var add = 'Add "' + request.term + '"'
                    items.push({
                      label: add,
                      value: request.term,
                      id: request.term,
                      class: 'fs-unmask',
                    })
                  }
                  response(items)
                })
              },
            })

            $job_title_input.on('focus', function () {
              if (has_job_title_autocomplete) {
                $(this).autocomplete('search', $(this).val())
              }
            })

            has_job_title_autocomplete = true
            $job_title_field
              .removeClass('fm-property-job_title--other')
              .fadeIn(initialising ? 0 : 0)
          } else if (seniority !== 'advisory_board_mod') {
            if (has_job_title_autocomplete) {
              $job_title_input.autocomplete('destroy').val('')
              has_job_title_autocomplete = false
            }
            $job_title_field
              .removeClass('fm-property-job_title--other')
              .fadeOut(initialising ? 0 : 0, function () {})
          }

          if (kind === 'student') {
            $seniority_label.text('More specifically, I’m a…')
          } else {
            $seniority_label.text(original_seniority_label)
          }
          if (kind === 'professional') {
            $job_title_field.find('label').text('More specifically, I’m a…')
          } else {
            $job_title_field.find('label').text(original_job_title_label)
          }
          $button.text(submit_text)
          $(
            '.fm-fieldset--other-contact, .fm-fieldset--other-message, .fm-description--other-contact'
          ).fadeOut(initialising ? 0 : 260, function () {
            $(this).css('display', 'none')
            if (seniority !== 'advisory_board_mod') {
              $('.fm-fieldset--institution, .fm-fieldset--specialty')
                .css('display', 'block')
                .fadeIn(initialising ? 0 : 260)
            }
          })
          if (seniority === 'advisory_board_mod') {
            $('.fm-fieldset--institution, .fm-fieldset--specialty').addClass(
              'hide'
            )
          }
          $('.fm-field-c-primary_spec').show()
          if (
            kind === 'dentist' ||
            (kind === 'student' && seniority === 'dental')
          ) {
            $('.fm-field-c-primary_spec').hide()
            $('.fm-property-primary_spec__search').val('Dentistry')
            $('.fm-property-primary_spec select')
              .val('dentistry.dentistry')
              .trigger('change')
            enableFieldset($('.fm-fieldset--specialty'))
          } else if (kind === 'doctor' && seniority === 'gp') {
            $('.fm-property-primary_spec__search').val('General Practice')
            $('.fm-property-primary_spec select')
              .val('general_practice.general_practice')
              .trigger('change')
            enableFieldset($('.fm-fieldset--specialty'))
          } else if (kind === 'student') {
            if (seniority === 'pre_clinical') {
              $('.fm-property-primary_spec__search').val('Pre-clinical')
              $('.fm-property-primary_spec select')
                .val('pre_clinical.pre_clinical')
                .trigger('change')
              enableFieldset($('.fm-fieldset--specialty'))
            } else {
              $('.fm-property-primary_spec__search').val('All')
              $('.fm-property-primary_spec select').val('').trigger('change')
              disableFieldset($('.fm-fieldset--specialty'))
            }
          } else {
            if (
              !$('.fm-property-primary_spec select').data('1time') &&
              !$('.fm-property-primary_spec select').val()
            ) {
              $('.fm-property-primary_spec__search').val('')
              $('.fm-property-primary_spec select').val('').trigger('change')
              $('.fm-property-primary_spec select').data('1time', true)
            }
            enableFieldset($('.fm-fieldset--specialty'))
          }

          if (kind === 'student') {
            $('.fm-fieldset--specialty').fadeOut(
              initialising ? 0 : 260,
              function () {
                $(this).css('display', 'none')
                disableFieldset($('.fm-fieldset--specialty'))
              }
            )
          }
        }
      }
      $kind_select.on('change', updateRoleSpecialties).triggerHandler('change')
      $seniority_select.on('change', updateRoleSpecialties)
      $kind_select.closest('form').on('updateUI', updateRoleSpecialties)
      initialising = false
    }
  )

  // Specialty field search / autocomplete
  //
  $.cf.installer('.fm-fieldset--specialty', function ($section) {
    var $field = $section.find('.fm-property-primary_spec'),
      $specialty = $field.find('select'),
      $sub_specialty = $section.find(
        '.fm-property-primary_subspec .fm-fieldset__contents'
      ),
      $input = $('<input>', {
        type: 'text',
        class: 'fm-property-primary_spec__search fs-unmask',
      }),
      subspecialties = [],
      categories = $field.data('child-options'),
      datasource = new SpecialtyDatasource($field)
    $input.attr('placeholder', $specialty.find('option').first().text())
    $specialty.before($input)
    $specialty.hide()

    if ($specialty.val()) {
      $input.val(
        $specialty.find('option').eq($specialty.prop('selectedIndex')).text()
      )
    }
    $specialty.on('change', function () {
      if ($specialty.val()) {
        $input.val(
          $specialty.find('option').eq($specialty.prop('selectedIndex')).text()
        )
      }
    })

    $input.on('focus', function () {
      $input.autocomplete('search', '')
    })
    $input.on('click', function () {
      $input.autocomplete('search', '')
    })
    $input.on('blur', function () {
      if ($input.val() === '') {
        $specialty.val('')
      } else if ($specialty.val() === '') {
        $input.val('')
      } else {
        $input.val($specialty.find('option:selected').text())
      }
      $specialty.triggerHandler('change')
    })

    $input.autocomplete({
      minLength: 0,

      source: function (request, response) {
        datasource.updateFilterTerm(request.term, response)
      },

      select: function (e, ui) {
        $specialty.val(ui.item.id)
        $specialty.trigger('change')
        $input.get(0).blur()
        if (ui.item.subspecialty_id) {
          setTimeout(function () {
            $sub_specialty
              .find('input')
              .filter(function () {
                return this.value != ui.item.subspecialty_id
              })
              .prop('checked', false)
          }, 1)
        }
      },

      open: function (e, ui) {
        $input.autocomplete('widget').width($input.width())
      },
    })
    var auto = $input.data('autocomplete') || $input.data('ui-autocomplete')
    auto._renderItem = function (ul, item) {
      var $a = $('<a>')
      $a.append($('<span>', { text: item.title }))
      return $('<li class="fs-unmask">')
        .data('item.autocomplete', item)
        .append($a)
        .appendTo(ul)
    }
  })

  // Mobile version of Role / Where / Specialty sections
  //
  $.cf.installer('.fm-section--profile', function ($root) {
    var was_palm, $mobile_root
    var $kind = $('.fm-property-kind select')

    function updateFields() {
      if (G.cfui.mediaQuery('palm')) {
        if ($kind.val() === 'other') {
          $root.show()
          $root.find('[data-screen]').hide()
          $root
            .parent()
            .find('.mobile-picker-button')
            .filter(function () {
              return $(this).data('screen') != 'role'
            })
            .hide()
        } else {
          $root.hide()
          $root.find('[data-screen]').show()
          $root.parent().find('.mobile-picker-button').show()
        }
      } else {
        $root.show()
        $root.find('[data-screen]').show()
        $root.parent().find('.mobile-picker-button').show()
      }
      $kind.triggerHandler('change')
    }

    function updateUI() {
      if (G.cfui.mediaQuery('palm')) {
        if (was_palm !== true) {
          was_palm = true
          if (typeof $mobile_root === 'undefined') {
            $mobile_root = $('<div>', { class: 'fm-fields--mobile-profile' })
            $mobile_root.append($('<p>', { text: 'Professional profile' }))
            var $legends = $root.find('.fm-fields--profile > fieldset > legend')
            var buttons = {}
            var screens = {}
            $legends.each(function () {
              var $legend = $(this),
                id = $legend.parent().data('screen'),
                $button
              if (typeof controllers[id] === 'function') {
                $button = $('<div>', {
                  class: 'mobile-picker-button',
                  'data-screen': id,
                })
                  .append(
                    $('<div>', {
                      class: 'mobile-picker-button__label',
                      text: $legend.text(),
                    })
                  )
                  .append(
                    $('<div>', {
                      class: 'mobile-picker-button__value',
                      text: '',
                    })
                  )
                $mobile_root.append($button)
                screens[id] = new controllers[id]($root, $button)
              }
            })
            $root.before($mobile_root)

            $mobile_root.on('click', function (e) {
              var $el = $(e.target).closest('.mobile-picker-button')
              if ($el.length) {
                var nav = showNavigationController()
                var screen_name = $el.data('screen')
                screens[screen_name].show(nav, $root, buttons[screen_name])
              }
            })
          }
          $mobile_root.show()
          $root.hide()
        }
      } else if (was_palm === true) {
        was_palm = false
        $mobile_root.hide()
        $root.show()
      }

      updateFields()
    }

    $root.closest('form').on('updateUI', updateUI)
    $(window).on('resize', function (e) {
      pushDisabledFade(true)
      updateUI()
      popDisabledFade()
    })
    updateUI()
  })

  var getSelectLabels = function ($select) {
    var labels = []
    $select.find('option').each(function () {
      var $this = $(this)
      if ($this.val() !== '') {
        labels.push($this.text())
      }
    })
    return labels
  }

  var setSelectValueByIndex = function ($select, index) {
    $select.get(0).selectedIndex = 1 + index
    $select.triggerHandler('change')
  }

  var RolePickerController = function ($form, $button) {
    this.$form = $form
    this.$button = $button
    this.$kind = $form.find('.fm-property-kind select')
    this.$seniority = $form.find('.fm-property-seniority select')
    this.$job_title = $form.find('.fm-property-job_title input')
    this.updateButton()
    var self = this
    this.$kind.on('change', function () {
      self.updateButton()
    })
    this.$seniority.on('change', function () {
      self.updateButton()
    })
  }

  RolePickerController.prototype.needsSeniority = function () {
    return !(
      this.$seniority.prop('disabled') ||
      this.$seniority.find('option').length <= 1
    )
  }

  RolePickerController.prototype.updateButton = function () {
    if (this.needsSeniority()) {
      if (this.$seniority.val()) {
        this.$button.addClass('mobile-picker-button--has-value')
        var $option = this.$kind.find('option:selected'),
          role = $option.text(),
          seniority = this.$seniority.find('option:selected').text()
        if ($option.attr('value') === 'student' && seniority !== '') {
          this.$button.find('.mobile-picker-button__value').text(seniority)
        } else {
          this.$button
            .find('.mobile-picker-button__value')
            .text(role + ', ' + this.$seniority.find('option:selected').text())
        }
        return
      }
    } else if (this.$job_title.val()) {
      this.$button.addClass('mobile-picker-button--has-value')
      this.$button
        .find('.mobile-picker-button__value')
        .text(this.$job_title.val())
      return
    } else if (this.$kind.val()) {
      this.$button.addClass('mobile-picker-button--has-value')
      this.$button
        .find('.mobile-picker-button__value')
        .text(this.$kind.find('option:selected').text())
      return
    }
    this.$button.removeClass('mobile-picker-button--has-value')
    this.$button.find('.mobile-picker-button__value').text('')
  }

  RolePickerController.prototype.show = function (nav) {
    var screen = new ListController(
      'I’m a…',
      new ArrayDatasource(getSelectLabels(this.$kind))
    )
    nav.push(screen)

    var self = this

    $(screen).on({
      didselect: function (e, index, item) {
        setSelectValueByIndex(self.$kind, index)
        self.updateButton()
        self.$form.closest('form').trigger('updateUI')

        setTimeout(function () {
          var kind = self.$kind.val()
          if (self.needsSeniority()) {
            var label = 'Grade'
            if (kind === 'student') label = 'More specifically, I’m a…'
            else if (kind === 'other') label = 'Your field'
            var subScreen = new ListController(
              label,
              new ArrayDatasource(getSelectLabels(self.$seniority))
            )
            nav.push(subScreen)

            $(subScreen).on({
              didselect: function (e, index, item) {
                setSelectValueByIndex(self.$seniority, index)
                self.updateButton()
                nav.hide()
              },
            })
          } else if (
            kind === 'nurse' ||
            kind === 'professional' ||
            kind === 'other'
          ) {
            var label = 'More specifically, I’m a…'
            var subScreen = new ListController(
              label,
              new AjaxDatasource(
                '/api/job-titles/search?autocomplete=1&kind=' + kind
              ),
              true
            )
            nav.push(subScreen)

            $(subScreen).on({
              didselect: function (e, index, item) {
                self.$job_title.val(item.label)
                self.updateButton()
                nav.hide()
              },
            })
          } else {
            nav.hide()
          }
        }, 10)
      },
    })
  }

  var InstitutionPickerController = function ($form, $button) {
    this.$form = $form
    this.$button = $button
    this.$main_institution = $form.find('.fm-property-main_institution')
    this.$search = this.$main_institution.find('input[type="text"]')
    this.$hidden = this.$main_institution.find('input[type="hidden"]')
    this.updateButton()
    var self = this
    this.$hidden.on('change', function () {
      self.updateButton()
    })
  }

  InstitutionPickerController.prototype.updateButton = function () {
    if (this.$hidden.val().length > 0 && this.$hidden.val() != '0') {
      this.$button.addClass('mobile-picker-button--has-value')
      this.$button.find('.mobile-picker-button__value').text(this.$search.val())
    } else {
      this.$button.removeClass('mobile-picker-button--has-value')
      this.$button.find('.mobile-picker-button__value').text('')
    }
  }

  InstitutionPickerController.prototype.show = function (nav) {
    var screen = new ListController(
      'Your place of work',
      new AjaxDatasource('/api/institutions/search'),
      true
    )
    nav.push(screen)

    screen.filter()

    var self = this

    $(screen).on({
      didselect: function (e, index, item) {
        self.$search.val(item.title)
        self.$hidden.val(item.id)
        setTimeout(function () {
          self.updateButton()
          nav.hide()
        }, 10)
      },
    })
  }

  var SpecialtyPickerController = function ($form, $button) {
    this.$form = $form
    this.$button = $button
    this.$primary_specialty = $form.find('.fm-property-primary_spec select')
    this.$primary_subspecialty = $form.find(
      '.fm-property-primary_subspec .fm-fieldset__contents'
    )
    this.$kind = $form.find('.fm-property-kind select')
    this.$seniority = $form.find('.fm-property-seniority select')
    this.updateButton()
    var self = this
    this.$primary_specialty.on('change', function () {
      self.updateButton()
    })
    this.$primary_subspecialty.on('change', function () {
      self.updateButton()
    })
  }

  SpecialtyPickerController.prototype.needsSubSpecialty = function () {
    return !(
      this.$primary_subspecialty.prop('disabled') ||
      this.$primary_subspecialty.find('input').length === 0
    )
  }

  SpecialtyPickerController.prototype.updateButton = function () {
    if (this.needsSubSpecialty()) {
      var nsubs = this.$primary_subspecialty.find('input:checked').length
      if (nsubs) {
        this.$button.addClass('mobile-picker-button--has-value')
        this.$button
          .find('.mobile-picker-button__value')
          .text(this.$primary_specialty.find('option:selected').text())
        return
      }
    } else if (this.$primary_specialty.val()) {
      this.$button.addClass('mobile-picker-button--has-value')
      this.$button
        .find('.mobile-picker-button__value')
        .text(this.$primary_specialty.find('option:selected').text())
      return
    } else if (
      this.$kind.val() === 'student' &&
      this.$seniority.val() === 'clinical'
    ) {
      this.$button.addClass('mobile-picker-button--has-value')
      this.$button.find('.mobile-picker-button__value').text('All')
      return
    }

    this.$button.removeClass('mobile-picker-button--has-value')
    this.$button.find('.mobile-picker-button__value').text('')
  }

  SpecialtyPickerController.prototype.show = function (nav) {
    var self = this

    var screen = new ListController(
      'Your specialty',
      new SpecialtyDatasource(
        self.$primary_specialty.parents('.fm-field').first()
      ),
      true
    )
    nav.push(screen)

    var updateSubspecialty = function (item) {
      self.$primary_subspecialty
        .find('input')
        .filter(function () {
          return this.value != item.subspecialty_id
        })
        .prop('checked', false)
      self.$primary_subspecialty
        .find('input')
        .filter(function () {
          return this.value == item.subspecialty_id
        })
        .prop('checked', true)
      setTimeout(function () {
        self.updateButton()
      }, 10)
    }

    $(screen).on({
      didselect: function (e, index, item) {
        self.$primary_specialty.val(item.id)
        self.$primary_specialty.triggerHandler('change')

        setTimeout(function () {
          self.updateButton()

          if (item.subspecialty_id) {
            updateSubspecialty(item)
          }
          if (self.needsSubSpecialty()) {
            var subScreen = new MultiListController(
              'Subspecialty',
              new ArrayDatasource(
                self.$primary_subspecialty.find('label').map(function () {
                  return $(this).text()
                })
              )
            )
            if (item.subspecialty_id) {
              var $checks = self.$primary_subspecialty.find('input')
              var i = 0
              $checks.each(function () {
                if (this.value == item.subspecialty_id) {
                  return false
                }
                ++i
              })
              subScreen.selectOne(i)
            }
            nav.push(subScreen)

            $(subScreen).on({
              didselect: function (e, indexes) {
                var $checks = self.$primary_subspecialty.find(
                  'input[type=checkbox]'
                )
                $checks.prop('checked', false)
                $.each(indexes, function (i, index) {
                  $checks.eq(index).prop('checked', true)
                })
                self.updateButton()
                nav.hide()
              },
            })
          } else {
            nav.hide()
          }
        }, 10)
      },
    })
  }

  var showNavigationController = function () {
    var scrollY

    var nav = new NavigationController()
    $(nav).on({
      didpush: function () {
        if (typeof scrollY === 'undefined') {
          scrollY = $(document).scrollTop()
        }
        $('.page-body').addClass('page-body--disabled')
      },
      willpop: function () {
        if (nav.stack.length === 0) {
          $('.page-body').removeClass('page-body--disabled')
          setTimeout(function () {
            $(document).scrollTop(scrollY)
          }, 10)
        }
      },
      willremove: function () {
        $('.page-body').removeClass('page-body--disabled')
        setTimeout(function () {
          $(document).scrollTop(scrollY)
        }, 10)
      },
    })

    nav.show()
    return nav
  }

  var controllers = {
    role: RolePickerController,
    main_institution: InstitutionPickerController,
    specialty: SpecialtyPickerController,
  }

  var NavigationController = function () {
    this.$el = $('<div>', { class: 'ui-navigation' })
    this.stack = []
  }

  NavigationController.prototype.show = function ($optional_container) {
    $optional_container = $optional_container || $(document.body)
    $(this).trigger('willrender')
    $optional_container.append(this.$el)
  }

  NavigationController.prototype.hide = function () {
    $(this).trigger('willremove')
    this.$el.hide('fade', 260)
    var self = this
    setTimeout(function () {
      self.$el.detach()
    }, 260)
  }

  NavigationController.prototype.updateBar = function (
    $screen,
    childController
  ) {
    var self = this
    var $bar = $('<div>', { class: 'ui-screen__navbar ui-navbar' })
    var $title = $('<div>', {
      class: 'ui-navbar__title',
      text: childController.title,
    })
    var $back = $('<div>', { class: 'ui-navbar__back' })
    $screen.append($bar.append($back, $title))
    if (childController.rightBarButton) {
      var button = childController.rightBarButton(),
        $btn = $('<span>', { class: 'ui-navbar__button', text: button.label })
      $bar.append($btn)
      $btn.on('click', function () {
        button.onclick()
      })
    }
    $back.on('click', function () {
      self.pop()
    })
  }

  NavigationController.prototype.push = function (childController) {
    var self = this

    this.stack.push(childController)
    $(this).trigger('willpush')
    childController.willRender && childController.willRender()
    var $screen = $('<div>', { class: 'ui-screen ui-screen--entering' }),
      $screen_inner = $('<div>', { class: 'ui-screen__inner' }).appendTo(
        $screen
      )
    if (this.stack.length === 1) {
      $screen.addClass('ui-screen--root')
    }
    this.updateBar($screen, childController)
    $screen_inner.append(childController.$el)
    this.$el.append($screen)
    setTimeout(function () {
      $screen.removeClass('ui-screen--entering')
      $screen.addClass('ui-screen--enter')
      setTimeout(function () {
        $screen.removeClass('ui-screen--enter')
        childController.didRender && childController.didRender()
        $(self).trigger('didpush')
      }, 260)
    }, 10)
  }

  NavigationController.prototype.top = function () {
    return this.stack[this.stack.length - 1]
  }

  NavigationController.prototype.pop = function () {
    var childController = this.stack.pop()

    $(this).trigger('willpop')
    childController.willRemove && childController.willRemove()
    var $screen = this.$el.children().last()
    $screen.addClass('ui-screen--leaving')
    var self = this
    setTimeout(function () {
      $screen.removeClass('ui-screen--leaving')
      $screen.addClass('ui-screen--leave')
      setTimeout(function () {
        $screen.remove()
        childController.didRemove && childController.didRemove()
        $(self).trigger('didpop')
        if (self.stack.length === 0) {
          self.hide()
        }
      }, 260)
    }, 10)
  }

  var ListController = function (title, dataSource, allowFilter) {
    var self = this
    this.title = title
    this.dataSource = dataSource
    this.$el = $('<div>', { class: 'ui-list' })
    if (allowFilter) {
      this.$search = $('<input>', {
        class: 'ui-list__search',
        type: 'text',
      }).attr('placeholder', 'Search')
      this.$el.append(
        $('<div>', { class: ' ui-list__search-bar' }).append(this.$search)
      )
      var throttle
      this.$search.on('change keyup', function () {
        if (self.$search.val().length < 3) return
        if (!throttle) {
          throttle = setTimeout(function () {
            throttle = null
            self.filter()
          }, 900)
        }
      })
    }
    this.$el.on('click', '.ui-list__cell', function (e) {
      $(self).trigger('didselect', [
        $(e.target).data('index'),
        $(e.target).data('item'),
      ])
    })
  }

  ListController.prototype.willRender = function () {
    this.reloadData()
  }

  ListController.prototype.didRender = function () {
    if (this.$search && this.$search.length) {
      this.$search.get(0).focus()
    }
  }

  ListController.prototype.reloadData = function () {
    this.$el.find('.ui-list__cell').remove()
    for (var i = 0; i < this.dataSource.numberOfItems(); ++i) {
      this.$el.append(this.dataSource.cellForIndex(i))
    }
  }

  ListController.prototype.filter = function () {
    var self = this
    var term = this.$search.val()
    this.dataSource.updateFilterTerm(term, function () {
      self.reloadData()
    })
  }

  var MultiListController = function (title, dataSource, allowFilter) {
    var self = this
    this.title = title
    this.dataSource = dataSource
    this.$el = $('<div>', { class: 'ui-list ui-list--multi' })
    if (allowFilter) {
      this.$search = $('<input>', {
        class: 'ui-list__search',
        type: 'text',
      }).attr('placeholder', 'Search')
      this.$el.append(
        $('<div>', { class: ' ui-list__search-bar' }).append(this.$search)
      )
      var throttle
      this.$search.on('change keyup', function () {
        if (self.$search.val().length < 3) return
        if (!throttle) {
          throttle = setTimeout(function () {
            throttle = null
            self.filter()
          }, 900)
        }
      })
    }
    this.$el.on('click', '.btn--done', function (e) {
      self.didTapDone()
    })
    this.$el.on('click', '.ui-list__cell', function (e) {
      $(e.target).toggleClass('ui-list__cell--checked')
    })
    this.preSelectedIndices = []
  }

  MultiListController.prototype.willRender = function () {
    this.reloadData()
  }

  MultiListController.prototype.didRender = function () {
    if (this.$search && this.$search.length) {
      this.$search.get(0).focus()
    }
  }

  MultiListController.prototype.didTapDone = function () {
    var indices = this.$el.find('.ui-list__cell--checked').map(function () {
      return $(this).data('index')
    })
    $(this).trigger('didselect', [indices])
  }

  MultiListController.prototype.rightBarButton = function () {
    var self = this
    return {
      label: 'Done',
      onclick: function () {
        self.didTapDone()
      },
    }
  }

  MultiListController.prototype.selectOne = function (index) {
    this.preSelectedIndices = [index]
    this.$el
      .find('.ui-list__cell--checked')
      .removeClass('ui-list__cell--checked')
    this.$el.find('.ui-list__cell').eq(index).addClass('ui-list__cell--checked')
  }

  MultiListController.prototype.reloadData = function () {
    this.$el.find('.ui-list__cell').remove()
    for (var i = 0; i < this.dataSource.numberOfItems(); ++i) {
      var $cell = this.dataSource.cellForIndex(i)
      if (
        this.preSelectedIndices.length === 0 ||
        -1 < this.preSelectedIndices.indexOf(i)
      ) {
        $cell.addClass('ui-list__cell--checked')
      }
      this.$el.append($cell)
    }
    this.$el.append(
      $('<div>', { class: 'ui-list__footer' }).append(
        $('<button>', {
          class: 'btn btn--pill btn--wide btn--done',
          text: 'Done',
        })
      )
    )
    this.preSelectedIndices = []
  }

  MultiListController.prototype.filter = function () {
    var self = this
    var term = this.$search.val()
    this.dataSource.updateFilterTerm(term, function () {
      self.reloadData()
    })
  }

  var ArrayDatasource = function (list) {
    this.list = list
  }

  ArrayDatasource.prototype.numberOfItems = function () {
    return this.list.length
  }

  ArrayDatasource.prototype.itemTextForIndex = function (i) {
    return this.list[i]
  }

  ArrayDatasource.prototype.cellForIndex = function (i) {
    return $('<div>', {
      class: 'ui-list__cell',
      text: this.list[i],
      'data-index': i,
      'data-item': this.list[i],
    })
  }

  var FilteredDatasource = function (innerDatasource) {
    this.dataSource = innerDatasource
    this.term = ''
    this.items = null
  }

  FilteredDatasource.prototype.numberOfItems = function () {
    if (this.items) {
      return this.items.length
    }
    return this.dataSource.numberOfItems()
  }

  FilteredDatasource.prototype.cellForIndex = function (i) {
    if (this.items) {
      return this.items[i]
    }
    return this.dataSource.cellForIndex(i)
  }

  FilteredDatasource.prototype.updateFilterTerm = function (term, callback) {
    this.items = []
    term = term.toLowerCase()
    for (var i = 0; i < this.dataSource.numberOfItems(); ++i) {
      var item = this.dataSource.itemTextForIndex(i)
      if (-1 < item.toLowerCase().indexOf(term)) {
        this.items.push(this.dataSource.cellForIndex(i))
      }
    }
    callback()
  }

  var AjaxDatasource = function (api_url) {
    this.api_url = api_url
    this.list = []
  }

  AjaxDatasource.prototype.numberOfItems = function () {
    return this.list.length
  }

  AjaxDatasource.prototype.itemTextForIndex = function (i) {
    return this.list[i].title
  }

  AjaxDatasource.prototype.cellForIndex = function (i) {
    var $cell = $('<div>', {
      class: 'ui-list__cell',
      text: this.list[i].item_title
        ? this.list[i].item_title
        : this.list[i].title,
      'data-index': i,
    })
    $cell.data('item', this.list[i])
    return $cell
  }

  AjaxDatasource.prototype.updateFilterTerm = function (term, callback) {
    var self = this
    $.ajax({
      url: this.api_url,
      data: { term: term },
      success: function (response) {
        self.list = response.results

        var found = false,
          lterm = term.toLowerCase()
        $.each(self.list, function (k, v) {
          if (typeof v.title === 'undefined' && typeof v.label === 'string') {
            v.title = v.label
          }
          if (v.title.toLowerCase() === lterm) {
            found = true
            return false
          }
        })
        if (!found && term.length) {
          self.list.push({
            id: 'create:' + term,
            item_title: 'Create "' + term + '"',
            title: term,
          })
        }

        callback()
      },
    })
  }

  var SpecialtyDatasource = function ($primary_specialty_field) {
    this.filteredItems = null
    this.specialties = []
    this.subspecialties = []
    this.$specialty = $primary_specialty_field.find('select')

    var categories = $primary_specialty_field.data('child-options'),
      self = this
    var specialtyItem = function ($option) {
      var item = { id: $option.attr('value'), specialty_label: $option.text() }
      if (item.id != '') {
        if ($option.parent().is('optgroup')) {
          item.field_label = $option.parent().attr('label')
          item.title = item.field_label + ', ' + item.specialty_label
        } else {
          item.title = item.specialty_label
        }
        item.label = item.specialty_label
        return item
      }
    }

    this.$specialty.find('option').each(function () {
      var item = specialtyItem($(this))
      if (item) {
        self.specialties.push(item)
      }
    })

    $.each(categories, function (specialty_id, subs) {
      $.each(subs, function (subspecialty_id, subspecialty_label) {
        var $option = self.$specialty.find(
            'option[value="' + specialty_id + '"]'
          ),
          item = specialtyItem($option)
        item.subspecialty_id = subspecialty_id
        item.subspecialty_label = subspecialty_label
        item.title = item.specialty_label + ', ' + subspecialty_label
        self.subspecialties.push(item)
      })
    })
  }

  SpecialtyDatasource.prototype.numberOfItems = function () {
    if (this.filteredItems) {
      return this.filteredItems.length
    }
    return this.specialties.length
  }

  SpecialtyDatasource.prototype.itemForIndex = function (i) {
    if (this.filteredItems) {
      return this.filteredItems[i]
    }
    return this.specialties[i]
  }

  SpecialtyDatasource.prototype.itemTextForIndex = function (i) {
    return this.itemForIndex(i).title
  }

  SpecialtyDatasource.prototype.cellForIndex = function (i) {
    var item = this.itemForIndex(i)
    var $cell = $('<div>', {
      class: 'ui-list__cell',
      text: item.title,
      'data-index': i,
    })
    $cell.data('item', item)
    return $cell
  }

  SpecialtyDatasource.prototype.updateFilterTerm = function (term, callback) {
    term = term.toLowerCase()
    var matches = this.specialties.filter(function (item) {
      return -1 < item.specialty_label.toLowerCase().indexOf(term)
    })
    if (term.length) {
      $.each(this.subspecialties, function (i, sub) {
        if (-1 < sub.subspecialty_label.toLowerCase().indexOf(term)) {
          matches.push(sub)
        }
      })
    }
    this.filteredItems = matches
    callback(matches)
  }
})(jQuery, window)
